const formatTrialEnd = (dateString) => {
  const yyyymmdd = dateString?.split("T")[0];
  return yyyymmdd;
};

const getDaysBetween = (startDate, endDate) => Math.floor((new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24)) + 1;

const dates = {
  formatTrialEnd,
  getDaysBetween,
};

export default dates;
