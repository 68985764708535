import React, { useReducer } from "react";
import axios from "axios";
import mainContext from "./mainContext";
import mainReducer from "./mainReducer";
import {
  STRIPE_CUSTOMER,
  STRIPE_SUBSCRIPTION_EXTENDED,
  STRIPE_SUBSCRIPTION_PREVIEW,
  STRIPE_INVOICE,
  LOGIN,
  SEARCH,
  CHANGE_TAB,
  POST_CREATE_TEAM,
  MARK_INVOICE_SENT,
  DEMO_FLOW_DONE,
  GET_MEMBER,
  GET_TEAM,
  STRIPE_PRICE_CONFIG,
  POST_UPGRADE_TEAM,
  POST_SET_EMAIL_STEP,
  SET_ENV,
  STRIPE_CUTOMER_PAYMENT_METHODS,
  GET_HR_CONNECTIONS,
  GET_AD_GROUPS,
  GET_AD_GROUP_USERS,
  UPDATE_HR_CONNECTION_SYNC_CONF,
  GET_MS_AD_MAPPING_CONF,
  POST_CREATE_DEMO_TEAM,
  PATCH_UPDATE_DEMO_TEAM,
  ALERT,
  LOOKUP_TEXT,
} from "./types";
import { axiosInstance, axiosParagonInstance } from "../../utilities/axios.config";

const MainState = (props) => {
  const initialState = {
    user: null,
    currentTab: 0,
    listOfApps: [
      // {
      //   title: "Create custom Stripe subscription",
      //   description: "Edit, manage, or create a team's Stripe subscription",
      //   tabName: "Custom Billing",
      //   tabId: 1,
      // },
      {
        title: "Search for members and teams",
        description: "Query the database for specific users and teams",
        tabName: "Search",
        tabId: 2,
        permissions: {
          search: true,
        },
      },
      {
        title: "Create New Team",
        description: "Create a new team and initialize a subscription",
        tabName: "Create New Team",
        tabId: 3,
        permissions: {
          sales: true,
        },
      },
      {
        title: "Checkout Links",
        description: "Get direct checkout links for sharing",
        tabName: "Checkout Links",
        tabId: 4,
        permissions: {
          sales: true,
        },
      },
      {
        title: "Quotes",
        description: "Make a PDF Quote",
        tabName: "Quotes",
        tabId: 5,
        permissions: {
          sales: true,
        },
      },
      {
        title: "HR Sync",
        description: "Get users data from ad",
        tabName: "HR Sync",
        tabId: 6,
        permissions: {
          admin: true,
        },
      },
    ],
    stripe_customer: null,
    stripe_customer_payment_methods: null,
    stripe_subscription_preview: null,
    stripe_subscription_extended: null,
    stripe_invoice: null,
    postDemoFlowStoredEmail: "",
    postDemoFlowStoredTeamId: 0,
    postDemoFlowStoredTeamSize: 0,
    postDemoFlowStoredLoginToken: null,
    postDemoFlowStoredpriceId: "",
    postDemoTeamCreated: false,
    postDemoFlowUpgrade: false,
    invoiceSent: false,
    postDemoFlowFinished: false,
    member: null,
    isProd: true,
    adGroupUsers: {},
    inputLookupText: "",
    snackbarData: {
      isOpen: false,
      severity: "",
      duration: 0,
      message: "",
    },
  };

  const [state, dispatch] = useReducer(mainReducer, initialState);

  // Functions

  const snackbarAction = (snackbarData) => {
    dispatch({
      type: ALERT,
      payload: snackbarData,
    });
  };

  const onChangeInputLookupText = (lookupData) => {
    dispatch({
      type: LOOKUP_TEXT,
      payload: lookupData,
    });
  };

  const resetState = () => {
    dispatch({
      type: STRIPE_CUSTOMER,
      payload: null,
    });
    dispatch({
      type: STRIPE_CUTOMER_PAYMENT_METHODS,
      payload: null,
    });
    dispatch({
      type: STRIPE_SUBSCRIPTION_EXTENDED,
      payload: null,
    });
    dispatch({
      type: STRIPE_SUBSCRIPTION_PREVIEW,
      payload: null,
    });
    dispatch({
      type: STRIPE_INVOICE,
      payload: null,
    });
    dispatch({
      type: SEARCH,
      payload: null,
    });
    dispatch({
      type: GET_MEMBER,
      payload: null,
    });
    dispatch({
      type: GET_TEAM,
      payload: null,
    });
    dispatch({
      type: GET_HR_CONNECTIONS,
      payload: null,
    });
    dispatch({
      type: POST_SET_EMAIL_STEP,
      email: null,
    });
    dispatch({
      type: GET_AD_GROUPS,
      payload: null,
    });
    dispatch({
      type: GET_AD_GROUP_USERS,
      payload: {},
    });
    dispatch({
      type: GET_MS_AD_MAPPING_CONF,
      payload: null,
    });
  };

  const changeTab = (tab) => {
    dispatch({
      type: CHANGE_TAB,
      payload: tab,
    });
  };

  const logout = () => {
    localStorage.removeItem("@qrToken");
    dispatch({
      type: LOGIN,
      payload: null,
    });
    resetState();

    // Redirect to login
    window.location.href = "/";
  };

  const resetDemoTeamState = () => {
    dispatch({
      type: STRIPE_CUTOMER_PAYMENT_METHODS,
      payload: null,
    });
    dispatch({
      type: STRIPE_SUBSCRIPTION_EXTENDED,
      payload: null,
    });
    dispatch({
      type: STRIPE_SUBSCRIPTION_PREVIEW,
      payload: null,
    });
    dispatch({
      type: STRIPE_INVOICE,
      payload: null,
    });
    dispatch({
      type: POST_CREATE_DEMO_TEAM,
      postDemoTeamCreated: false,
    });
  };

  // Login
  const login = async (google_credential, callbackFunc) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const data = {
        google_credential,
      };

      const res = await axiosInstance.post("/internal/auth/login_sso", data, config);

      // Save auth token
      localStorage.setItem("@qrToken", res.data.token);
      dispatch({
        type: LOGIN,
        payload: res.data.user,
      });
      dispatch({
        type: SET_ENV,
        isProd: res.data.isProd,
      });

      callbackFunc();
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data && error.response.data.message) {
        callbackFunc(error.response.data.message);
      } else {
        callbackFunc(error);
      }
    }
  };

  // Search
  const searchMembersAndTeams = async (search_term, callbackFunc) => {
    console.log("SEARCH TERM HERE", search_term);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const data = { query: search_term };

      const res = await axiosInstance.post("/internal/search/query", data, config);

      // Save auth token
      dispatch({
        type: SEARCH,
        payload: res.data,
      });

      callbackFunc();
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data && error.response.data.message) {
        callbackFunc(error.response.data.message);
      } else {
        callbackFunc(error);
      }
    }
  };

  // Search Member
  const getMemberById = async (memberId, callbackFunc) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const data = { memberId };

      const res = await axiosInstance.post("/internal/member/get_by_id", data, config);

      // Save auth token
      dispatch({
        type: GET_MEMBER,
        payload: res.data,
      });

      callbackFunc();
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data && error.response.data.message) {
        callbackFunc(error.response.data.message);
      } else {
        callbackFunc(error);
      }
    }
  };

  const deleteMember = async (userId, userEmail) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await axiosInstance.delete(`/internal/member/${userId}`, config);
      await searchMembersAndTeams(state.inputLookupText, () => {});

      snackbarAction({
        message: `Successfully deleted member ${userEmail}!`,
        severity: "success",
        duration: 3000,
      });
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) {
        snackbarAction({
          message: error.response.data?.message
            ? error.response.data?.message
            : JSON.stringify(error.response.data),
          severity: "error",
          duration: 3000,
        });
      }
    }
  };

  const updateMember = async (userId, body, cb) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axiosInstance.patch(`/internal/member/${userId}`, body, config);
      const member = data?.data;

      snackbarAction({
        message: "Successfully updated member!",
        severity: "success",
        duration: 3000,
      });

      cb(member);
      searchMembersAndTeams(state.inputLookupText, () => {});
    } catch (error) {
      console.log(error);
      cb({ email: "" });
      if (error.response && error.response.data) {
        snackbarAction({
          message: error.response.data?.message
            ? error.response.data?.message
            : JSON.stringify(error.response.data),
          severity: "error",
          duration: 3000,
        });
      }
    }
  };

  const setMemberAdmin = async (memberId, teamId, isAdmin, callbackFunc) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const data = { memberId, teamId, isAdmin };

      const res = await axiosInstance.post("/internal/member/change_admin", data, config);

      // Save auth token
      dispatch({
        type: GET_MEMBER,
        payload: res.data,
      });

      callbackFunc();
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data && error.response.data.message) {
        callbackFunc(error.response.data.message);
      } else {
        callbackFunc(error);
      }
    }
  };

  const setEmailVerified = async (memberId, emailVerified, callbackFunc) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const data = { memberId, emailVerified };

      const res = await axiosInstance.post("/internal/member/verify_email", data, config);

      // Save auth token
      dispatch({
        type: GET_MEMBER,
        payload: res.data,
      });

      callbackFunc();
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data && error.response.data.message) {
        callbackFunc(error.response.data.message);
      } else {
        callbackFunc(error);
      }
    }
  };

  const getLoginToken = async (uid, callbackFunc) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const data = { uid };

      const res = await axiosInstance.post("/internal/member/get_login_token", data, config);

      callbackFunc(null, res.data?.loginToken);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data && error.response.data.message) {
        callbackFunc(error.response.data.message);
      } else {
        callbackFunc(error);
      }
    }
  };

  // Search Team
  const getTeamById = async (teamId, callbackFunc) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const data = { teamId };

      const res = await axiosInstance.post("/internal/team/get_by_id", data, config);

      // Save auth token
      dispatch({
        type: GET_TEAM,
        payload: res.data,
      });

      callbackFunc();
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data && error.response.data.message) {
        callbackFunc(error.response.data.message);
      } else {
        callbackFunc(error);
      }
    }
  };

  const getHRconnectionsByTeamId = async (teamId, callbackFunc) => {
    try {
      const data = { teamId };

      const res = await axiosInstance.post("/internal/hr-sync/get_hr_connections", data);

      const token = await axiosInstance.post("/internal/hr-sync/get-admin-token", {
        teamId: res.data[0].parentTeamId,
      });
      const mappingConf = await axios(`/connections/hrSync/map-conf/${res.data[0].parentTeamId}`, {
        baseURL: process.env.REACT_APP_POPLBE_URL,
        headers: {
          Authorization: token.data.token,
        },
        method: "GET",
      });

      dispatch({
        type: GET_HR_CONNECTIONS,
        payload: res.data,
      });

      dispatch({
        type: GET_MS_AD_MAPPING_CONF,
        payload: mappingConf.data.data,
      });

      callbackFunc();
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data && error.response.data.message) {
        callbackFunc(error.response.data.message);
      } else {
        callbackFunc(error);
      }
    }
  };

  // Price Config
  const getPriceConfig = async (callbackFunc) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axiosInstance.get("/internal/stripe/price_config", config);

      dispatch({
        type: STRIPE_PRICE_CONFIG,
        priceConfig: res.data.priceConfig,
        couponConfig: res.data.couponConfig,
      });

      callbackFunc();
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data && error.response.data.message) {
        callbackFunc(error.response.data.message);
      } else {
        callbackFunc(error);
      }
    }
  };

  /* Preview */
  const previewStripeSubscriptionCurrentPricing = async (data, callbackFunc) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axiosInstance.post(
        "/internal/stripe/subscription/preview/current_pricing",
        data,
        config,
      );

      dispatch({
        type: STRIPE_SUBSCRIPTION_PREVIEW,
        payload: res.data,
      });

      callbackFunc();
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data && error.response.data.message) {
        callbackFunc(error.response.data.message);
      } else {
        callbackFunc(error);
      }
    }
  };
  const previewStripeSubscriptionCustomPricing = async (data, callbackFunc) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axiosInstance.post(
        "/internal/stripe/subscription/preview/custom_pricing",
        data,
        config,
      );

      dispatch({
        type: STRIPE_SUBSCRIPTION_PREVIEW,
        payload: res.data,
      });

      callbackFunc();
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data && error.response.data.message) {
        callbackFunc(error.response.data.message);
      } else {
        callbackFunc(error);
      }
    }
  };

  const createDemoTeam = async (data, callbackFunc) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await axiosInstance.post("/internal/stripe/demo_teams", data, config);

      dispatch({
        type: POST_CREATE_DEMO_TEAM,
        postDemoTeamCreated: true,
      });

      dispatch({
        type: DEMO_FLOW_DONE,
      });

      callbackFunc();
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data) {
        snackbarAction({
          message: error.response.data?.message
            ? error.response.data.message
            : JSON.stringify(error.response.data),
          severity: "error",
          duration: 3000,
        });
      }
    }
  };

  const updateDemoTeam = async (id, params) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data: updatedDemoTeam } = await axiosInstance.patch(
        `/internal/stripe/demo_teams/${id}`,
        { trialDays: params.trialDays },
        config,
      );

      dispatch({
        type: PATCH_UPDATE_DEMO_TEAM,
      });

      await getTeamById(updatedDemoTeam?.data?.teamId, (err) => {
        if (err) {
          alert(err);
        }
      });

      snackbarAction({
        message: "Successfully updated internal demo team!",
        severity: "success",
        duration: 3000,
      });
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data) {
        snackbarAction({
          message: error.response.data?.message
            ? error.response.data.message
            : JSON.stringify(error.response.data),
          severity: "error",
          duration: 3000,
        });
      }
    }
  };

  const downloadQuote = async (data, callbackFunc) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        includeCheckout: data.includeCheckout,
      },
      responseType: "blob",
    };

    try {
      const res = await axiosInstance.get(`/internal/quotes/pdf/${data.teamId}`, config);

      // Response is a blob
      const file = new Blob([res.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);

      const link = document.createElement("a");
      link.href = fileURL;
      const safeCompanyName = data.companyName.replace(/[^a-zA-Z0-9]/g, "_");
      link.setAttribute("download", `Popl Teams Quote - ${safeCompanyName}.pdf`);
      document.body.appendChild(link);
      link.click();

      callbackFunc();
      return res.data;
    } catch (error) {
      callbackFunc(error);
    }
  };

  const setDemoFlowDone = (event) => {
    event.preventDefault();
    exitPostDemoFlow();

    dispatch({
      type: CHANGE_TAB,
      payload: 0,
    });
  };

  /* Subscription */
  const setTeamPricing = async (data, callbackFunc) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axiosInstance.post("/internal/stripe/set_team_pricing", data, config);

      dispatch({
        type: STRIPE_SUBSCRIPTION_EXTENDED,
        payload: res.data,
      });

      callbackFunc();
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) {
        snackbarAction({
          message: error.response.data?.message
            ? error.response.data?.message
            : JSON.stringify(error.response.data),
          severity: "error",
          duration: 3000,
        });
      }
    }
  };

  /* Invoice */
  const sendInvoice = async (data, callbackFunc) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axiosInstance.post("/internal/stripe/invoice/send", data, config);

      dispatch({
        type: STRIPE_INVOICE,
        payload: res.data,
      });

      callbackFunc();
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data && error.response.data.message) {
        callbackFunc(error.response.data.message);
      } else {
        callbackFunc(error);
      }
    }
  };

  /* Payment Method */
  const addPaymentMethod = async (data, callbackFunc) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axiosInstance.post("/internal/stripe/add_payment_method", data, config);

      callbackFunc();
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data && error.response.data.message) {
        callbackFunc(error.response.data.message);
      } else {
        callbackFunc(error);
      }
    }
  };

  /* Payment Method */
  const getCustomerPaymentMethods = async (data, callbackFunc) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axiosInstance.post(
        "/internal/stripe/get_customer_payment_methods",
        data,
        config,
      );

      dispatch({
        type: STRIPE_CUTOMER_PAYMENT_METHODS,
        payload: res.data?.paymentMethods,
      });

      callbackFunc();
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data && error.response.data.message) {
        callbackFunc(error.response.data.message);
      } else {
        callbackFunc(error);
      }
    }
  };

  /* Post Demo Flow */
  const createTeam = async (data, callbackFunc) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      if (
        data.memberEmails.filter((x) => !x.includes("@") || !x.includes(".") || x.includes(" "))
          .length >= 1
      ) {
        callbackFunc();
        return alert("Bad email(s) detected");
      }

      const res = await axiosInstance.post("/internal/team/create_team", data, config);

      dispatch({
        type: POST_CREATE_TEAM,
        id: res.data?.team?.id,
        email: res.data?.team?.email,
        loginToken: res.data?.loginToken,
        stripeCustomer: res.data?.stripeCustomer,
        postDemoFlowUpgrade: false,
        teamSize: res.data?.teamSize,
      });

      callbackFunc();
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data && error.response.data.message) {
        alert(error.response.data.message);
        callbackFunc();
      } else {
        alert("Server error - please refresh");
        callbackFunc();
      }
    }
  };

  const changeTeamPlan = async (teamId, teamEmail, stripeCustomer, teamSize) => {
    dispatch({
      type: POST_UPGRADE_TEAM,
      email: teamEmail,
      id: teamId,
      stripeCustomer,
      postDemoFlowUpgrade: true,
      teamSize,
    });
  };

  const linkTeamToStripeSub = async (callbackFunc) => {
    dispatch({
      type: DEMO_FLOW_DONE,
    });

    callbackFunc();
  };

  const exitPostDemoFlow = () => {
    resetState();
    resetDemoTeamState();
    dispatch({
      type: POST_CREATE_TEAM,
      email: "",
      id: 0,
      priceId: "",
      postDemoFlowUpgrade: false,
      postDemoFlowFinished: false,
      loginToken: null,
      teamSize: 0,
    });
    dispatch({
      type: STRIPE_CUTOMER_PAYMENT_METHODS,
      payload: null,
    });
  };

  const markInvoiceSent = () => {
    dispatch({
      type: MARK_INVOICE_SENT,
    });
  };

  const setPostFlowEmail = (email) => {
    dispatch({
      type: POST_SET_EMAIL_STEP,
      email,
    });
  };

  /* Quote */
  const generatePdfQuote = async (data, callbackFunc) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "blob",
      };
      const res = await axiosInstance.post("/internal/quotes/make_pdf", data, config);

      // Response is a blob
      const file = new Blob([res.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);

      const link = document.createElement("a");
      link.href = fileURL;
      const safeCompanyName = data.companyName.replace(/[^a-zA-Z0-9]/g, "_");
      link.setAttribute("download", `Popl Teams Quote - ${safeCompanyName}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      callbackFunc();
    } catch (error) {
      // Attempt to decode blob as JSON
      error.response.data
        .text()
        .then((text) => {
          try {
            text = JSON.parse(text);
          } catch (e) {
            console.log(e);
          }
          if (text && text.msg) {
            callbackFunc(text.msg);
          } else {
            callbackFunc(error);
          }
        })
        .catch((err) => {
          console.log(err);
          callbackFunc(error);
        });
    }
  };

  /* Team Order */
  const addTeamOrder = async (data, callbackFunc) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axiosInstance.post("/internal/team/add_order", data, config);

      callbackFunc();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        callbackFunc(error.response.data.message);
      } else {
        callbackFunc(error);
      }
    }
  };

  // Get AD gorup users
  const getAdGroups = async (groupIds, connectionUid, cb) => {
    try {
      const { data } = await axiosInstance.post("/internal/hr-sync/get_token", {
        uid: connectionUid,
      });
      const groups = await axiosParagonInstance.get(
        `/groups?$filter=id eq '${groupIds[0]}'&$select=id,displayName`,
        {
          headers: { Authorization: `Bearer ${data.token}` },
        },
      );
      dispatch({
        type: GET_AD_GROUPS,
        payload: groups.data?.value,
      });
      cb();
    } catch (error) {
      cb();
      console.log(error);
    }
  };

  const updateSyncConfig = async (body) => {
    try {
      await axiosInstance.put("/internal/hr-sync/update-sync-config", body);
      dispatch({
        type: UPDATE_HR_CONNECTION_SYNC_CONF,
        payload: body,
      });
    } catch (error) {
      console.log(error);
    }
  };

  /* Team Order */
  const setFirewallIp = async (data, callbackFunc) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axiosInstance.post("/internal/firewall/firewall_update_ip", data, config);
      callbackFunc();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        callbackFunc(error.response.data.message);
      } else {
        callbackFunc(error);
      }
    }
  };

  return (
    <mainContext.Provider
      value={{
        user: state.user,
        currentTab: state.currentTab,
        listOfApps: state.listOfApps,
        stripe_customer: state.stripe_customer,
        stripe_customer_payment_methods: state.stripe_customer_payment_methods,
        stripe_subscription_preview: state.stripe_subscription_preview,
        stripe_subscription_extended: state.stripe_subscription_extended,
        stripe_invoice: state.stripe_invoice,
        search_results: state.search_results,
        postDemoFlowStoredEmail: state.postDemoFlowStoredEmail,
        postDemoFlowStoredTeamId: state.postDemoFlowStoredTeamId,
        postDemoFlowStoredTeamSize: state.postDemoFlowStoredTeamSize,
        postDemoFlowStoredLoginToken: state.postDemoFlowStoredLoginToken,
        postDemoFlowStoredpriceId: state.postDemoFlowStoredpriceId,
        postDemoFlowUpgrade: state.postDemoFlowUpgrade,
        postDemoTeamCreated: state.postDemoTeamCreated,
        invoiceSent: state.invoiceSent,
        postDemoFlowFinished: state.postDemoFlowFinished,
        member: state.member,
        team: state.team,
        hrConnections: state.hrConnections,
        price_config: state.price_config,
        coupon_config: state.coupon_config,
        prePostDemoFlowStoredEmail: state.prePostDemoFlowStoredEmail,
        isProd: state.isProd,
        adGroups: state.adGroups,
        hrMappingConf: state.hrMappingConf,
        login,
        logout,
        changeTab,
        previewStripeSubscriptionCurrentPricing,
        previewStripeSubscriptionCustomPricing,
        setTeamPricing,
        createDemoTeam,
        updateDemoTeam,
        sendInvoice,
        resetState,
        resetDemoTeamState,
        searchMembersAndTeams,
        createTeam,
        exitPostDemoFlow,
        markInvoiceSent,
        linkTeamToStripeSub,
        getMemberById,
        setMemberAdmin,
        snackbarData: state.snackbarData,
        snackbarAction,
        setEmailVerified,
        getTeamById,
        getPriceConfig,
        changeTeamPlan,
        setPostFlowEmail,
        getLoginToken,
        generatePdfQuote,
        addTeamOrder,
        addPaymentMethod,
        getCustomerPaymentMethods,
        getHRconnectionsByTeamId,
        getAdGroups,
        updateSyncConfig,
        setFirewallIp,
        downloadQuote,
        setDemoFlowDone,
        deleteMember,
        updateMember,
        onChangeInputLookupText,
        inputLookupText: state.inputLookupText,
      }}
    >
      {props.children}
    </mainContext.Provider>
  );
};

export default MainState;
